import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../components';
import ProductDetailSale from '../../components/sale/ProductDetailSale';
import { getVersionService } from '../../services/sale/servipagosService';
import { Grid } from '@mui/material';

const SaleTabScreen = () => {
  const navigate = useNavigate();
  const { showDetail } = useSelector(store => store.airTimeSale);
  const { bags } = useSelector(store => store.session);
  const bagTAE = bags.find(bag => bag.type === 'TAE');
  const bagPDS = bags.find(bag => bag.type === 'PDS');
  useEffect(() => {
    getVersionService()
    // eslint-disable-next-line
  }, [])

  return (
    <Grid container style={{ marginTop: "-10px", marginLeft: "10px", height: 'calc(100% - 45px)' }} flex={1}>
      <Grid container item flex={1}>
        <CustomTab
          base='/venta'
          panels={[
            bagTAE && {
              name: "Tiempo Aire",
              route: '/venta/tiempo-aire',
              onChange: () => navigate('tiempo-aire'),
              content: <Outlet />
            },
            bagPDS && {
              name: "Pago de Servicios",
              route: '/venta/servicios',
              onChange: () => navigate('servicios'),
              content: <Outlet />
            },
            bagPDS && {
              name: "Pines Electrónicos",
              route: '/venta/pines-electronicos',
              onChange: () => navigate('pines-electronicos'),
              content: <Outlet />
            }
          ].filter(Boolean)}
        />
      </Grid>
      {
        showDetail && (
          <Grid container item flex={1}>
            <ProductDetailSale />
          </Grid>
        )
      }
    </Grid>
  )
}

export default SaleTabScreen;