import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
  bags: [],
  typeOperation: [],
  operators: [],
  regions: [],
  segments: [],
  denominations: [],
  currentBag: null,
  currentOperator: null,
  currentRegion: null,
  currentSegment: null,
  earningsScheme: [],
  earningsPayment: [],
  types: [],
  justProducts: false,
  //PDS
  pdsSegments: [],
  pdsProducts: [],
  currentPDSSegment: null,
  currentPDSProduct: null,
  //PIN
  pinSegments: [],
  pinProducts: [],
  currentPINSegment: null,
  currentPINProduct: null,
};

export const bagSlice = createSlice({
  name: "bags",
  initialState,
  reducers: {
    setBagFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setBags: (state, action) => ({
      ...state,
      bags: action.payload,
    }),
    setOperators: (state, action) => ({
      ...state,
      operators: action.payload,
    }),
    setRegions: (state, action) => ({
      ...state,
      regions: action.payload,
    }),
    setSegments: (state, action) => ({
      ...state,
      segments: action.payload,
    }),
    setDenominations: (state, action) => ({
      ...state,
      denominations: action.payload,
    }),
    addBag: (state, action) => ({
      ...state,
      bags: [...state.bags, action.payload],
    }),
    updateBag: (state, action) => ({
      ...state,
      bags: state.bags.map((bag) => {
        if (bag._id === action.payload.id)
          return { ...bag, ...action.payload.data };
        return bag;
      }),
    }),
    removeBag: (state, action) => ({
      ...state,
      bags: state.bags.filter((bag) => {
        return bag._id !== action.payload;
      }),
    }),
    updateOperator: (state, action) => ({
      ...state,
      operators: state.operators.map((operator) => {
        if (operator._id === action.payload.id)
          return { ...operator, ...action.payload.data };
        return operator;
      }),
    }),
    updateRegion: (state, action) => ({
      ...state,
      regions: state.regions.map((region) => {
        if (region._id === action.payload.id)
          return { ...region, ...action.payload.data };
        return region;
      }),
    }),
    updateSegment: (state, action) => ({
      ...state,
      segments: state.segments.map((segment) => {
        if (segment._id === action.payload.id)
          return { ...segment, ...action.payload.data };
        return segment;
      }),
    }),
    updateDenomination: (state, action) => ({
      ...state,
      denominations: state.denominations.map((denomination) => {
        if (denomination._id === action.payload.id)
          return { ...denomination, ...action.payload.data };
        return denomination;
      }),
    }),
    setCurrentBag: (state, action) => ({
      ...state,
      currentBag: action.payload,
    }),
    setCurrentOperator: (state, action) => ({
      ...state,
      currentOperator: action.payload,
    }),
    setCurrentRegion: (state, action) => ({
      ...state,
      currentRegion: action.payload,
    }),
    setCurrentSegment: (state, action) => ({
      ...state,
      currentSegment: action.payload,
    }),
    setCurrentDenomination: (state, action) => ({
      ...state,
      currentDenomination: action.payload,
    }),
    setTypeOperation: (state, action) => ({
      ...state,
      typeOperation: action.payload,
    }),
    setEarningsScheme: (state, action) => ({
      ...state,
      earningsScheme: action.payload
    }),
    setEarningsPayment: (state, action) => ({
      ...state,
      earningsPayment: action.payload
    }),
    setTypes: (state, action) => ({
      ...state,
      types: action.payload
    }),
    setJustProducts: (state, action) => ({
      ...state,
      justProducts: action.payload
    }),

    //PDS
    setPDSSegments: (state, action) => ({
      ...state,
      pdsSegments: action.payload
    }),
    setPDSProducts: (state, action) => ({
      ...state,
      pdsProducts: action.payload
    }),
    setCurrentPDSSegment: (state, action) => ({
      ...state,
      currentPDSSegment: action.payload
    }),
    setCurrentPDSProduct: (state, action) => ({
      ...state,
      currentPDSProduct: action.payload
    }),
    //PIN
    setPINSegments: (state, action) => ({
      ...state,
      pinSegments: action.payload
    }),
    setPINProducts: (state, action) => ({
      ...state,
      pinProducts: action.payload
    }),
    setCurrentPINSegment: (state, action) => ({
      ...state,
      currentPINSegment: action.payload
    }),
    setCurrentPINProduct: (state, action) => ({
      ...state,
      currentPINProduct: action.payload
    }),
  },
});
export const {
  setBagFilters,
  setBags,
  setOperators,
  setRegions,
  setSegments,
  setDenominations,
  addBag,
  updateBag,
  updateOperator,
  updateRegion,
  updateSegment,
  updateDenomination,
  setCurrentBag,
  setCurrentOperator,
  setCurrentRegion,
  setCurrentSegment,
  setCurrentDenomination,
  setTypeOperation,
  removeBag,
  setEarningsScheme,
  setEarningsPayment,
  setTypes,
  setJustProducts,

  //PDS
  setPDSSegments,
  setPDSProducts,
  setCurrentPDSSegment,
  setCurrentPDSProduct,
  //PIN
  setPINSegments,
  setPINProducts,
  setCurrentPINSegment,
  setCurrentPINProduct
} =
  bagSlice.actions;

export default bagSlice.reducer;
