import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../helpers/alerts';
import { addSalePoint } from '../../../redux/slices/client/salePointSlice';
import { createSalePointService } from '../../../services/client/salePointService';
import SalePointForm from '../../../components/client/SalePointForm';

const CreateSalePointScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentCommerce } = useSelector((store) => store.commerces);
console.log(currentCommerce);

  const handlerSubmit = async (data) => {
    data.commerce = currentCommerce._id;
    createSalePointService(data).then((response) => {
      dispatch(addSalePoint(response));
      navigate("/cliente/puntos_de_venta");
    }).catch((e) => errorAlert(e))
  }

  return (
    <SalePointForm
      goBack={() => navigate(-1)}
      submit={handlerSubmit}
      defaultValues={{
        days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      }}
    />
  )
}

export default CreateSalePointScreen