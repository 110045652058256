import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  updadateBalanace: 0,
  bags: []
}

export const sessionSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSession: (state, action) => ({
      ...state,
      ...action.payload
    }),
    cleanSession: () => initialState
  }
})

export const { setSession, cleanSession } = sessionSlice.actions

export default sessionSlice.reducer

