import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { errorAlert } from '../../helpers/alerts';
import { currencyFormatter, validateEmptyFields } from '../../helpers/util';
import { setTypeOperation } from '../../redux/slices/client/bagSlice';
import { getTypeOperationService } from '../../services/client/bagService';
import InputSelectFieldGrid from '../common/InputSelectFieldGrid';
import InputTextFieldGrid from '../common/InputTextFieldGrid';
import './BagOperationForm.css'

const BagOperationForm = ({ title, bag, submit, validate }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();
  const { typeOperation: types } = useSelector((store) => store.bags);
  const [disabled, setDisabled] = useState(true);
  const watch = useWatch({ control });

  useEffect(() => {
    getTypeOperationService()
      .then((response) => dispatch(setTypeOperation(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  const handlerSubmit = (data) => submit(data, bag);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={5}>
        <div>
        </div>
        <div className='container-title'>
          <div className='commerce'>{title} </div>
          <div className='balance'>Saldo: {currencyFormatter.format(bag.saldo)} </div>
        </div>
        <div>
        </div>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mt={2}>
        <Grid width={"70%"} container spacing={3}>
          <InputTextFieldGrid
            xs={4}
            name={"payment"}
            control={control}
            required={"Debes agregar la cantidad abonada"}
            label={"Importe del deposito ($)"}
            inputType={'number'}
            step={'any'}
          />
          <InputTextFieldGrid
            xs={4}
            name={"reference"}
            control={control}
            required={"Debes agregar la referencia"}
            label={"Referencia"}
          />
          <InputSelectFieldGrid
            xs={4}
            name={"type"}
            control={control}
            required={"Debes seleccionar un tipo"}
            label={"Seleccionar tipo"}
            data={types}
            disabled={validate !== undefined}
          />
          <Grid container justifyContent="center" mt={2}>
            <Button
              sx={{ fontWeight: "bold", backgroundColor: "#0E73ED" }}
              onClick={handleSubmit(handlerSubmit)}
              disabled={disabled}
              variant="contained"
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default BagOperationForm