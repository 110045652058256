import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errorAlert } from '../../../helpers/alerts';
import { hasSameValues } from '../../../helpers/util';
import { updateSalePointService } from '../../../services/client/salePointService';
import { updateSalePoint } from '../../../redux/slices/client/salePointSlice';
import SalePointForm from '../../../components/client/SalePointForm';
import { setLoading } from '../../../redux/slices/uiSlice';

const EditSalePointScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentSalePoint: current } = useSelector((store) => store.salePoint);
 
  const values = {
    commerce: current.hoster?.id,
    name: current.name,
    type: current.type,
    description: current.description,
    email: current.email,
    days: current.days,
    close_hour: current.close_hour,
    open_hour: current.open_hour,
    limit_amount_day: current.limit_amount_day,
    nameUser: current.user?.name,
    lastnameUser: current.user?.lastname,
    emailUser: current.user?.email,
  }

  const handlerBack = () => {
    navigate("/cliente/puntos_de_venta");
  };

  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    updateSalePointService(current.id, data).then(() => {
      dispatch(updateSalePoint({ id: current.id, data }));
      navigate("/cliente/puntos_de_venta");
    }).catch((e) => errorAlert(e))
      .finally(() => dispatch(setLoading(false)))
  }

  const validate = (data) => !hasSameValues(values, data)
  return (
    <SalePointForm
      title={"Puntos de venta"}
      goBack={handlerBack}
      submit={handlerSubmit}
      validate={validate}
      defaultValues={values}
      user={current.user}
    />
  )
}

export default EditSalePointScreen