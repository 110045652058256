import service from "../service"

export const getSessionBagsService = () => (
  service('get', `v3/bolsaSaldo`)
)
export const getBagService = (bagId) => (
  service('get', `v3/bolsaSaldo/${bagId}`)
)

export const getBagsService = (data) => (
  service('post', `v3/bolsaSaldo`, { data })
)

export const createBagService = (data) => (
  service('put', `v3/bolsaSaldo`, { data })
)

export const updateBagService = (bagId, data) => (
  service('patch', `v3/bolsaSaldo/${bagId}`, { data })
)
export const removeBagService = (bagId) => (
  service('delete', `v3/bolsaSaldo/${bagId}`)
)

export const setBagOperatorPurchaseCommissionService = (balanceBagId, data) => (
  service('post', `v3/bolsaSaldo/${balanceBagId}/comision`, {
    data: { ...data }
  })
)

export const createBagOperationService = (idBag, data) => (
  service('post', `v3/bolsaSaldo/${idBag}/operacion`, {
    contentType: "multipart/form-data",
    form: { ...data }
  })
)

export const getTypeOperationService = () => new Promise((resolve) => resolve([
  { id: 'ABONO', name: 'Abonar Saldo' },
  // { id: 'BONIFICACIÓN', name: 'Bonificación' },
  { id: 'CARGO', name: 'Cargo' }
]))

export const getEarningsSchemeService = () => new Promise((resolve) => resolve([
  { id: 'COMISION', name: 'Comisión', },
  { id: 'DESCUENTO', name: 'Descuento' },
]))
export const getTypesService = () => new Promise((resolve) => resolve([
  { id: 'TAE', name: 'TAE', },
  { id: 'PDS', name: 'PDS' },
]))

export const getEarningsPaymentService = () => new Promise((resolve) => resolve([
  // { id: 'VENTA', name: 'Venta', },
  { id: 'COMPRA', name: 'Compra' },
  // { id: 'HIBRIDO', name: 'Hibrido' },
]))

//BagOperator

export const getBagOperatorService = (balanceBagId) => (
  service('get', `v3/comercio/bolsaSaldo/${balanceBagId}/operadores`)
)

export const updateAllBagOperatorCommissionService = (balanceBagId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operadores/comision`, {
    data: { ...data }
  })
)

export const updateBagOperatorStateService = (balanceBagId, operatorId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/estado`, {
    data: { ...data }
  })
)

export const updateBagOperatorCommissionService = (balanceBagId, operatorId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/comision`, {
    data: { ...data }
  })
)



//BagRegions
export const getBagRegionService = (balanceBagId, operatorId) => (
  service('get', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/regiones`)
)

export const updateBagRegionStateService = (balanceBagId, operatorId, regionId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/estado`, {
    data: { ...data }
  })
)

export const updateBagRegionCommissionService = (balanceBagId, operatorId, regionId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/comision`, {
    data: { ...data }
  })
)
//BagSegment
export const getBagSegmentService = (balanceBagId, operatorId, regionId = 0) => (
  service('get', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/segmentos`)
)

export const updateBagSegmentStateService = (balanceBagId, operatorId, regionId = 0, segmentId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/segmento/${segmentId}/estado`, {
    data: { ...data }
  })
)

export const updateBagSegmentCommissionService = (balanceBagId, operatorId, regionId = 0, segmentId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/segmento/${segmentId}/comision`, {
    data: { ...data }
  })
)

//BagDenomination
export const getBagDenominationsService = (balanceBagId, operatorId, regionId = 0, segmentId) => (
  service('get', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/segmento/${segmentId}/denominaciones`)
)

export const updateBagDenominationStateService = (balanceBagId, operatorId, regionId = 0, segmentId, denominationId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/segmento/${segmentId}/denominacion/${denominationId}/estado`, {
    data: { ...data }
  })
)

export const updateBagDenominationCommissionService = (balanceBagId, operatorId, regionId = 0, segmentId, denominationId, data) => (
  service('post', `v3/comercio/bolsaSaldo/${balanceBagId}/operador/${operatorId}/region/${regionId}/segmento/${segmentId}/denominacion/${denominationId}/comision`, {
    data: { ...data }
  })
)

//PDS
export const getBagPDSSegmentsService = (id) => (
  service('get', `v3/comercio/bolsaSaldo/${id}/pds/segmentos`)
)
export const createBagPDSSegmentsService = (id, idSegment) => (
  service('post', `v3/comercio/bolsaSaldo/${id}/pds/segmento/${idSegment}`)
)
export const deleteBagPDSSegmentsService = (id, idSegment) => (
  service('delete', `v3/comercio/bolsaSaldo/${id}/pds/segmento/${idSegment}`)
)

export const getAllBagPDSProductsService = (id) => (
  service('get', `v3/comercio/bolsaSaldo/${id}/pds/productos`)
)
export const getBagPDSProductsService = (id, idSegment) => (
  service('get', `v3/comercio/bolsaSaldo/${id}/pds/segmento/${idSegment}/productos`)
)
export const setBagPDSProductService = (id, idSegment, idProduct, data) => (
  service('post', `v3/comercio/bolsaSaldo/${id}/pds/segmento/${idSegment}/producto/${idProduct}`, { data })
)
export const updateBagPDSProductService = (id, idSegment, idProduct, data) => (
  service('patch', `v3/comercio/bolsaSaldo/${id}/pds/segmento/${idSegment}/producto/${idProduct}`, { data })
)

//PIN
export const getBagPINSegmentsService = (id) => (
  service('get', `v3/comercio/bolsaSaldo/${id}/pin/segmentos`)
)
export const createBagPINSegmentsService = (id, idSegment) => (
  service('post', `v3/comercio/bolsaSaldo/${id}/pin/segmento/${idSegment}`)
)
export const deleteBagPINSegmentsService = (id, idSegment) => (
  service('delete', `v3/comercio/bolsaSaldo/${id}/pin/segmento/${idSegment}`)
)

export const getAllBagPINProductsService = (id) => (
  service('get', `v3/comercio/bolsaSaldo/${id}/pin/productos`)
)
export const getBagPINProductsService = (id, idSegment) => (
  service('get', `v3/comercio/bolsaSaldo/${id}/pin/segmento/${idSegment}/productos`)
)
export const setBagPINProductService = (id, idSegment, idProduct, data) => (
  service('post', `v3/comercio/bolsaSaldo/${id}/pin/segmento/${idSegment}/producto/${idProduct}`, { data })
)
export const updateBagPINProductService = (id, idSegment, idProduct, data) => (
  service('patch', `v3/comercio/bolsaSaldo/${id}/pin/segmento/${idSegment}/producto/${idProduct}`, { data })
)