import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddCircleOutline, Password } from '@mui/icons-material';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { CustomButton, FilterBar, TableData } from '../../../components';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { getCommercesService } from '../../../services/client/commerceService';
import { setCommerces } from '../../../redux/slices/client/commerceSlice';
import { getSalePointTypesService, getSalePointsService, removeSalePointService, updateSalePointStateService } from '../../../services/client/salePointService';
import { removeSalePoint, setCurrentSalePoint, setSalePointFilters, setSalePointTypes, setSalePoints, updateSalePoint } from '../../../redux/slices/client/salePointSlice';
import { getBagsService } from '../../../services/client/bagService';
import { setBags } from '../../../redux/slices/client/bagSlice';
import { recoverPassword } from 'services/security/userServices';

const SalePointScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { salePoints, salePointTypes, filters } = useSelector(store => store.salePoint);
  const { hoster, bags: sessionBags } = useSelector(store => store.session);
  const { commerces } = useSelector((store) => store.commerces);
  const { bags } = useSelector((store) => store.bags);
  const [disabledBags, setDisabledBags] = useState(true)

  useEffect(() => {
    getCommercesService()
      .then((response) => dispatch(setCommerces(response)))
      .catch((e) => errorAlert(e))
    getSalePointTypesService()
      .then((response) => dispatch(setSalePointTypes(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  const handlerFilter = (data) => {
    setDisabledBags(data.commerce === undefined)
    if (data.commerce)
      getBagsService(data)
        .then((response) => dispatch(setBags(response)))
        .catch((e) => errorAlert(e))
    getSalePointsService(data)
      .then((response) => dispatch(setSalePoints(response)))
      .catch((e) => errorAlert(e))
      .finally(() => dispatch(setSalePointFilters({})))
  }


  const handlerAdd = () => {
    navigate("crear");
  };

  const handlerEdit = (data) => {
    dispatch(setCurrentSalePoint(data));
    navigate("editar");
  };

  const handlerCheck = async (data, currentValue) => {
    const value = !currentValue;
    updateSalePointStateService(data.id, { status: value })
      .then(() => dispatch(updateSalePoint({ id: data.id, data: { status: value } })))
      .catch((e) => errorAlert(e))
  };

  const handlerDelete = ({ id, name }) => {
    confirmAlert({
      title: "Estás seguro que deseas eliminar este punto de venta?",
      text: `El punto de venta: ${name} será eliminado.`,
      onConfirm: async () => {
        removeSalePointService(id)
          .then(() => dispatch(removeSalePoint(id)))
          .catch((e) => errorAlert(e))
      }
    })
  };

  const handlerResetPassword = (email) => {
    confirmAlert({
      title: `¿Estas seguro que deseas restablecer la contraseña del usuario ${email}?`,
      text: `Se enviará al usuario un correo electrónico con un enlace para restablecer`,
      onConfirm: () => {
        recoverPassword(email).then(() => {
          confirmAlert({
            icon: 'success',
            title: `¡Correo enviado exitosamente!`,
            showCancelButton: false,
          });
        }).catch((e) => errorAlert(e))
      },
    });
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerFilter}
        defaultValues={{
          status: true,
          ...filters
        }}
        filters={[
          {
            name: 'commerce',
            label: "Comercio",
            type: "select",
            all: 'Todos',
            data: [
              hoster && { id: hoster, name: 'Mis puntos de venta' },
              ...commerces.map(({ _id: id, username }) => ({ id, name: username }))
            ].filter(Boolean),
          },
          {
            name: 'bag',
            label: "Bolsa",
            type: "select",
            all: 'Todas',
            data: bags.map(({ _id: id, hashtag }) => ({ id, name: hashtag })),
            disabled: disabledBags
          },
          {
            name: 'type',
            label: "Tipo",
            type: "select",
            all: 'Todos',
            data: salePointTypes,
          },
          {
            name: 'status',
            label: "Estado",
            type: "select",
            data: [
              { name: "Todos" },
              { id: true, name: "Activos" },
              { id: false, name: "Inactivos" },
            ],
          },
        ]}

      />
      <TableData
        dataRows={salePoints.map(item => ({
          ...item,
          style: item.hoster?.id === hoster && { backgroundColor: '#f0e6d9' }
        }))}
        headerButtons={[
          (sessionBags && sessionBags?.length != 0) &&
          <CustomButton
            icon={<AddCircleOutline fontSize="large" />}
            key={'addSalePoint'}
            name={"Punto de venta"}
            click={handlerAdd}
          />
        ].filter(Boolean)}
        dataTitle={[
          "Comercio",
          "Id Comercio",
          "Nombre de Punto de Venta",
          "Tipo",
          "Usuario",
          "Estado",
        ]}
        formatter={({ hoster, name, type, status, user }) => [
          hoster?.username,
          hoster?.identification,
          name,
          type ? Array.isArray(type) ? type.join(', ') : type : '',
          user?.email,
          { name: "status", type: "boolean", value: status }
        ]}
        onChecked={handlerCheck}
        actions={[
          // validatePermission('comercios', 'comercios', ['editar']) &&
          {
            label: "Editar",
            icon: <EditIcon fontSize="medium" />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          {
            label: "Restablecer Contraseña",
            icon: <Password fontSize="medium" />,
            onClick: (data) => handlerResetPassword(data?.user?.email),
            validate: (data) => data.user
          },
          {
            label: "Eliminar",
            icon: <DeleteForeverIcon fontSize="medium" />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  )
}

export default SalePointScreen