import axios from "axios";
import service from "../service";
import soap from "../soap";

export const getFavorites = () => (
  service('get', 'v3/venta/pds/favoritos')
)

export const getLastMovements = () => (
  service('get', 'v3/venta/pds/ultimos_movimentos')
)

export const sendTiketByEmail = (email, data) => (
  service('post', 'v3/venta/pds/enviar_ticket', {
    data: {
      email,
      data
    }
  })
)

export const getVersionService = () => (
  soap('GetVersion', 'pds', {
    xml: (`<phon:GetVersion/>`)
  })
)

export const doStoreSale = async ({ username, password, provider, amount, phone, storeId, productDetail, reference, externalTrace, salepointId }) => {
  let ip = await axios.get('https://api.ipify.org');
  return soap('DoStoreSale', 'pds', {
    xml: (`
    <phon:DoStoreSale>
      <phon:user>${username}</phon:user>
      <phon:password>${password}</phon:password>
      <phon:storeId>${storeId}</phon:storeId>
      <phon:providerId>${provider}</phon:providerId>
      <phon:amount>${amount}</phon:amount>
      <phon:rechargeNumber>${phone}</phon:rechargeNumber>
      <phon:externalTrace>${externalTrace}</phon:externalTrace>
      <phon:productDetail>${productDetail}</phon:productDetail>
      <phon:reference>${reference}</phon:reference>
    </phon:DoStoreSale>
    `),
    headers: {
      'HEBE-Platform': 'web',
      'HEBE-Version': '1.0.0',
      'HEBE-IP': ip.data,
      'HEBE-Salepoint': salepointId
    }
  })
}

export const getPDSProductToSale = ({ product, balanceBag }) => (
  service('post', `v3/venta/pds/producto`, {
    product, 
    balanceBag
  })
)