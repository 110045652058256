import { Cancel } from '@mui/icons-material'
import { Checkbox, FormControl, FormHelperText, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Controller, set } from 'react-hook-form'

const InputSelectFieldGrid = ({ xs, name, size = 'small', control, required, label, data, style, formatter = (data) => data, onSelect = (value) => value, disabled = false, displayEmpty = false, multiple = false, render, onOpen, onClose, showClean }) => {
  const [selected, setSelected] = useState([])
  const list = formatter(data);

  return (
    <Grid
      item
      xs={xs}
      alignContent="center"
      justifyContent={"center"}
      style={{ ...style, width: '100%' }}
    >
      <Controller
        name={name}
        control={control}
        rules={!disabled && { required }}
        render={({
          field: { value, onChange },
          fieldState: { error }
        }) => {
          if (value && multiple) {
            value = typeof value === 'string' ? value.split(',') : value
            setSelected(value)
          }
          return (
            <FormControl fullWidth size={size}>
              {
                displayEmpty ? <InputLabel shrink>{label}</InputLabel> : <InputLabel>{label}</InputLabel>
              }
              <Select
                value={multiple ? (selected.length === 0 ? Array.isArray(value) ? value : value ? [value] : [] : selected) : (data.length === 0 ? '' : (value === undefined ? '' : value))}
                label={label}
                multiple={multiple}
                input={displayEmpty ? <OutlinedInput notched={displayEmpty} label={label} /> : <OutlinedInput label={label} />}
                displayEmpty={displayEmpty}
                onChange={(e) => {
                  let val = e.target.value
                  console.log(val);

                  if (multiple) {
                    val = typeof val === 'string' ? val.split(',') : val
                    setSelected(val)
                  }
                  onSelect(val)
                  onChange(value === val ? "" : val)
                }}
                error={!!error}
                disabled={disabled}
                renderValue={(value) => multiple ? render ? render(value) : value.join(', ') : list?.find((item) => item.id === value)?.name}
                onOpen={onOpen}
                onClose={onClose}
                IconComponent={showClean && value ? () => (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setSelected(null)
                      onSelect(null)
                      onChange(null)
                      // field.onChange("");
                    }}
                  >
                    <Cancel />
                  </IconButton>
                )
                  : undefined
                }
              >
                {list.map((item, i) => {
                  return (
                    <MenuItem key={"item_" + item.name + i} value={item.id} >
                      {
                        multiple ? (
                          <>
                            <Checkbox checked={selected?.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                          </>
                        ) : item.name
                      }
                    </MenuItem>
                  )
                })}
              </Select>
              {error && (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {required}
                </FormHelperText>
              )}
            </FormControl>
          )
        }}
      />
    </Grid>
  )
}

export default InputSelectFieldGrid