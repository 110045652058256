import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, TableData } from "components";
import { errorAlert } from "helpers/alerts";
import { getAllPDSProductsService, getPDSProductsService } from "services/admin/productService";
import { Grid } from "@mui/material";
import { setBagPDSProductService } from "services/client/bagService";
import { setPDSProducts } from "redux/slices/admin/productSlice";
import { setPDSProducts as setBagPDSProducts } from "redux/slices/client/bagSlice";

const BagPDSProductScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentCommerce } = useSelector((store) => store.commerces);
  const currentBag = currentCommerce.bags.find(bag => bag.type === 'PDS');
  const {
    justProducts,
    currentPDSSegment,
    pdsProducts: bagPDSProducts,
  } = useSelector((store) => store.bags);
  const {
    pdsProducts
  } = useSelector((state) => state.products);


  useEffect(() => {
    if (justProducts)
      getAllPDSProductsService()
        .then((data) => dispatch(setPDSProducts(data)))
        .catch(error => errorAlert(error))
    else
      getPDSProductsService(currentPDSSegment.id)
        .then((data) => dispatch(setPDSProducts(data)))
        .catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, []);

  const handlerCheck = async (row, state) => {
    setBagPDSProductService(currentBag.id, row.idSegment.id, row.id, {
      state: !state
    }).then(() => {
      dispatch(setBagPDSProducts(bagPDSProducts.map((item) => {
        if (item.idProduct.id === row.id) {
          return {
            ...item,
            state: !state
          };
        }
        return item;
      })))
    }).catch(error => errorAlert(error))
  };

  const handlerSetCommission = async (commission, row) => {
    setBagPDSProductService(currentBag.id, row.idSegment.id, row.id, {
      commission
    }).then((result) => {
      dispatch(setBagPDSProducts(bagPDSProducts.map((item) => {
        if (item.idProduct.id === row.id) {
          return {
            ...item,
            commission
          };
        }
        return item;
      })))
    }).catch(error => errorAlert(error))
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate(-1)} />
        </div>
        <div className="container-title">
          <div className="commerce">Servicios</div>
          {!justProducts && <div className="bag">{currentPDSSegment.name} </div>}
        </div>
        <div>
        </div>
      </Grid>
      <TableData
        dataRows={pdsProducts.map((item) => {
          const service = bagPDSProducts?.find(({ idProduct }) => idProduct.id === item.id);
          return {
            ...item,
            service
          };
        })}
        dataTitle={["Logo", "Nombre", "Comision del Canal", "Asignado"]}
        formatter={({ fd, name, service }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          { name: "commission", type: "input", value: service?.commission, onBlur: handlerSetCommission },
          { name: "state", type: "boolean", value: service?.state },
        ]}
        onChecked={handlerCheck}
      />
    </>
  );
};

export default BagPDSProductScreen;
